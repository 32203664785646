import React from 'react';
import './AboutSection.css';
function AboutSection() {
    return (
        <section className="about-section">
            <div className="container">
                <div className="about-content">
                    <h3>About Us</h3>
                    <p>
                    At Live Safe, we help people stay healthy and safe by providing easy-to-understand information about health and well-being, including pollen levels. Our mission is to make it simple for individuals and communities to make informed choices and stay protected. We use the latest technology to offer clear, reliable insights that help improve daily life and promote better health.
                    </p>
                </div>
                <div className="about-image">
                    <img src={`${process.env.PUBLIC_URL}/Aboutus.png`} alt="About Us in Melbourne" className="responsive-img" />
                </div>
            </div>
        </section>
    );
}

export default AboutSection;