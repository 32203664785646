import React, { useEffect, useState, useCallback, useRef } from 'react';
import { GoogleMap, LoadScript, InfoWindow } from '@react-google-maps/api';
import { useLocation } from 'react-router-dom';

const MapPoints = () => {
  const [center, setCenter] = useState({ lat: -37.8136, lng: 144.9631 });
  const [zoom, setZoom] = useState(12);
  const [hospitalMarkers, setHospitalMarkers] = useState([]);
  const [healthCenterMarkers, setHealthCenterMarkers] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [geoJsonData, setGeoJsonData] = useState(null);

  const [selectedHospitalCategories, setSelectedHospitalCategories] = useState([]);
  const [selectedHealthCenterCategories, setSelectedHealthCenterCategories] = useState([]);

  const apiUrl = 'http://localhost:3000/api';
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const location = useLocation();
  const postcode = location.state?.postcode || '';

  const mapRef = useRef(null);
  const hospitalMarkersRef = useRef([]);
  const healthCenterMarkersRef = useRef([]);
  const highlightedAreaRef = useRef(null);

  // Hardcoded categories
  const hospitalCategories = [
    'day procedure centre',
    'general hospital',
    'general hospital (emergency)'
  ];
  const healthCenterCategories = [
    'maternal/child health centre',
    'community health centre'
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch hospital data based on selected categories
        const fetchHospitalData = async (categories) => {
          const url = categories.length
            ? `${apiUrl}/hospitals?category=${categories.join(',')}`
            : `${apiUrl}/hospitals`;
          const response = await fetch(url);
          const data = await response.json();
          setHospitalMarkers(data.map((hospital) => ({
            position: { lat: parseFloat(hospital.latitude), lng: parseFloat(hospital.longitude) },
            title: hospital.name_label,
            category: hospital.category,
          })));
        };

        // Fetch health center data based on selected categories
        const fetchHealthCenterData = async (categories) => {
          const url = categories.length
            ? `${apiUrl}/health-centres?category=${categories.join(',')}`
            : `${apiUrl}/health-centres`;
          const response = await fetch(url);
          const data = await response.json();
          setHealthCenterMarkers(data.map((center) => ({
            position: { lat: parseFloat(center.latitude), lng: parseFloat(center.longitude) },
            title: center.name_label,
            category: center.category,
          })));
        };

        await fetchHospitalData(selectedHospitalCategories);
        await fetchHealthCenterData(selectedHealthCenterCategories);

        if (postcode) {
          const geoJsonResponse = await fetch(`${apiUrl}/geojson?postcode=${postcode}`);
          const geoJson = await geoJsonResponse.json();
          setGeoJsonData(geoJson);
        }

      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to fetch data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [postcode, selectedHospitalCategories, selectedHealthCenterCategories]);

  const updateMarkers = () => {
    if (mapRef.current) {
      clearMarkers(hospitalMarkersRef);
      clearMarkers(healthCenterMarkersRef);

      if (selectedHospitalCategories.length || selectedHealthCenterCategories.length) {
        const filteredHospitalMarkers = hospitalMarkers.filter(marker =>
          selectedHospitalCategories.includes(marker.category)
        );
        const filteredHealthCenterMarkers = healthCenterMarkers.filter(marker =>
          selectedHealthCenterCategories.includes(marker.category)
        );

        addMarkers(filteredHospitalMarkers, 'red', hospitalMarkersRef);
        addMarkers(filteredHealthCenterMarkers, 'pink', healthCenterMarkersRef);
      }
    }
  };

  const addMarkers = (markers, color, refArray) => {
    markers.forEach((marker) => {
      const mapMarker = new window.google.maps.Marker({
        position: marker.position,
        map: mapRef.current,
        title: marker.title,
        icon: {
          url: `http://maps.google.com/mapfiles/ms/icons/${color}-dot.png`,
        },
      });
      mapMarker.addListener('click', () => {
        setSelectedMarker(marker);
      });
      refArray.current.push(mapMarker);
    });
  };

  const clearMarkers = (refArray) => {
    refArray.current.forEach((marker) => marker.setMap(null));
    refArray.current = [];
  };

  const onLoad = useCallback((map) => {
    mapRef.current = map;

    if (geoJsonData) {
      if (highlightedAreaRef.current) {
        highlightedAreaRef.current.setMap(null);
      }

      map.data.addGeoJson(geoJsonData);
      map.data.setStyle({
        fillColor: '#e7c8a0',
        strokeColor: 'grey',
        strokeWeight: 2,
        fillOpacity: 0.6,
      });

      const highlightBounds = new window.google.maps.LatLngBounds();
      geoJsonData.features.forEach((feature) => {
        feature.geometry.coordinates[0].forEach(([lng, lat]) => {
          highlightBounds.extend(new window.google.maps.LatLng(lat, lng));
        });
      });

      map.fitBounds(highlightBounds);
    }

    updateMarkers();
  }, [geoJsonData]);

  useEffect(() => {
    updateMarkers();
  }, [selectedHospitalCategories, selectedHealthCenterCategories]);

  const handleCategoryChange = (category, type) => (event) => {
    const { checked } = event.target;
    if (type === 'hospital') {
      setSelectedHospitalCategories((prevCategories) =>
        checked ? [...prevCategories, category] : prevCategories.filter(c => c !== category)
      );
    } else {
      setSelectedHealthCenterCategories((prevCategories) =>
        checked ? [...prevCategories, category] : prevCategories.filter(c => c !== category)
      );
    }
  };

  const onUnmount = useCallback(() => {
    mapRef.current = null;
  }, []);

  return (
    <div style={styles.container}>
      <div style={styles.sidebar}>
        <h2>Filter by Category</h2>
        <div style={styles.filterSection}>
          <h3>Hospitals</h3>
          {hospitalCategories.map((category) => (
            <div key={category} style={styles.checkboxContainer}>
              <input
                type="checkbox"
                id={`hospital-${category}`}
                value={category}
                checked={selectedHospitalCategories.includes(category)}
                onChange={handleCategoryChange(category, 'hospital')}
              />
              <label htmlFor={`hospital-${category}`}>{category}</label>
            </div>
          ))}
        </div>
        <div style={styles.filterSection}>
          <h3>Health Centers</h3>
          {healthCenterCategories.map((category) => (
            <div key={category} style={styles.checkboxContainer}>
              <input
                type="checkbox"
                id={`health-center-${category}`}
                value={category}
                checked={selectedHealthCenterCategories.includes(category)}
                onChange={handleCategoryChange(category, 'healthCenter')}
              />
              <label htmlFor={`health-center-${category}`}>{category}</label>
            </div>
          ))}
        </div>
      </div>
      <div style={styles.mapContainer}>
        <h1 style={styles.title}>Points of Interest in Melbourne</h1>
        <p style={styles.instructions}>
          Use the checkboxes to filter points on the map.
        </p>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <LoadScript googleMapsApiKey={googleMapsApiKey}>
            <GoogleMap
              mapContainerStyle={styles.mapContainerStyle}
              center={center}
              zoom={zoom}
              onLoad={onLoad}
              onUnmount={onUnmount}
            >
              {selectedMarker && (
                <InfoWindow
                  position={selectedMarker.position}
                  onCloseClick={() => setSelectedMarker(null)}
                >
                  <div>
                    <h4>{selectedMarker.title}</h4>
                  </div>
                </InfoWindow>
              )}
            </GoogleMap>
          </LoadScript>
        )}
        {error && <p className="error">{error}</p>}
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '100%',
    maxWidth: '1200px',
    margin: 'auto',
  },
  sidebar: {
    width: '250px',
    padding: '20px',
    borderRight: '2px solid #ccc',
    height: '600px',
    overflowY: 'auto',
  },
  filterSection: {
    marginBottom: '20px',
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  mapContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
  },
  mapContainerStyle: {
    width: '100%',
    height: '600px',
    border: '5px solid #ccc',
    borderRadius: '10px',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)',
  },
  title: {
    textAlign: 'center',
    marginBottom: '10px',
  },
  instructions: {
    textAlign: 'center',
    marginBottom: '20px',
    fontSize: '14px',
    color: '#555',
  },
};

export default MapPoints;