import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import generateAdvice from './generateAdvice';

// Full list of options formatted for react-select
const optionsList = [
    { value: 'ABBOTSFORD', label: 'ABBOTSFORD' },
    { value: 'ALBERT PARK', label: 'ALBERT PARK' },
    { value: 'ALPHINGTON', label: 'ALPHINGTON' },
    { value: 'ARMADALE', label: 'ARMADALE' },
    { value: 'ASCOT VALE', label: 'ASCOT VALE' },
    { value: 'ASHBURTON', label: 'ASHBURTON' },
    { value: 'ASHWOOD', label: 'ASHWOOD' },
    { value: 'ASPENDALE', label: 'ASPENDALE' },
    { value: 'ASPENDALE GARDENS', label: 'ASPENDALE GARDENS' },
    { value: 'ATTWOOD', label: 'ATTWOOD' },
    { value: 'AUBURN', label: 'AUBURN' },
    { value: 'AVONDALE HEIGHTS', label: 'AVONDALE HEIGHTS' },
    { value: 'BALACLAVA', label: 'BALACLAVA' },
    { value: 'BALWYN', label: 'BALWYN' },
    { value: 'BALWYN NORTH', label: 'BALWYN NORTH' },
    { value: 'BAYSIDE', label: 'BAYSIDE' },
    { value: 'BAYSWATER', label: 'BAYSWATER' },
    { value: 'BAYSWATER NORTH', label: 'BAYSWATER NORTH' },
    { value: 'BEACONSFIELD', label: 'BEACONSFIELD' },
    { value: 'BEAUMARIS', label: 'BEAUMARIS' },
    { value: 'BELLFIELD', label: 'BELLFIELD' },
    { value: 'BENTLEIGH', label: 'BENTLEIGH' },
    { value: 'BENTLEIGH EAST', label: 'BENTLEIGH EAST' },
    { value: 'BERWICK', label: 'BERWICK' },
    { value: 'BLACKBURN', label: 'BLACKBURN' },
    { value: 'BLACKBURN NORTH', label: 'BLACKBURN NORTH' },
    { value: 'BLACKBURN SOUTH', label: 'BLACKBURN SOUTH' },
    { value: 'BLACK ROCK', label: 'BLACK ROCK' },
    { value: 'BONBEACH', label: 'BONBEACH' },
    { value: 'BOX HILL', label: 'BOX HILL' },
    { value: 'BOX HILL NORTH', label: 'BOX HILL NORTH' },
    { value: 'BOX HILL SOUTH', label: 'BOX HILL SOUTH' },
    { value: 'BRAYBROOK', label: 'BRAYBROOK' },
    { value: 'BRIAR HILL', label: 'BRIAR HILL' },
    { value: 'BRIGHTON', label: 'BRIGHTON' },
    { value: 'BRIGHTON EAST', label: 'BRIGHTON EAST' },
    { value: 'BROADMEADOWS', label: 'BROADMEADOWS' },
    { value: 'BROOKLYN', label: 'BROOKLYN' },
    { value: 'BRUNSWICK', label: 'BRUNSWICK' },
    { value: 'BRUNSWICK EAST', label: 'BRUNSWICK EAST' },
    { value: 'BRUNSWICK WEST', label: 'BRUNSWICK WEST' },
    { value: 'BULLEEN', label: 'BULLEEN' },
    { value: 'BUNDOORA', label: 'BUNDOORA' },
    { value: 'BURNLEY', label: 'BURNLEY' },
    { value: 'BURWOOD', label: 'BURWOOD' },
    { value: 'BURWOOD EAST', label: 'BURWOOD EAST' },
    { value: 'CAMBERWELL', label: 'CAMBERWELL' },
    { value: 'CAMPBELLFIELD', label: 'CAMPBELLFIELD' },
    { value: 'CANTERBURY', label: 'CANTERBURY' },
    { value: 'CARLTON', label: 'CARLTON' },
    { value: 'CARLTON NORTH', label: 'CARLTON NORTH' },
    { value: 'CARNEGIE', label: 'CARNEGIE' },
    { value: 'CAROLINE SPRINGS', label: 'CAROLINE SPRINGS' },
    { value: 'CARRUM', label: 'CARRUM' },
    { value: 'CARRUM DOWNS', label: 'CARRUM DOWNS' },
    { value: 'CAULFIELD', label: 'CAULFIELD' },
    { value: 'CAULFIELD EAST', label: 'CAULFIELD EAST' },
    { value: 'CAULFIELD NORTH', label: 'CAULFIELD NORTH' },
    { value: 'CAULFIELD SOUTH', label: 'CAULFIELD SOUTH' },
    { value: 'CHADSTONE', label: 'CHADSTONE' },
    { value: 'CHELTENHAM', label: 'CHELTENHAM' },
    { value: 'CHIRNSIDE PARK', label: 'CHIRNSIDE PARK' },
    { value: 'CLARINDA', label: 'CLARINDA' },
    { value: 'CLAYTON', label: 'CLAYTON' },
    { value: 'CLIFTON HILL', label: 'CLIFTON HILL' },
    { value: 'COBURG', label: 'COBURG' },
    { value: 'COBURG NORTH', label: 'COBURG NORTH' },
    { value: 'COLLINGWOOD', label: 'COLLINGWOOD' },
    { value: 'COOLAROO', label: 'COOLAROO' },
    { value: 'CRANBOURNE', label: 'CRANBOURNE' },
    { value: 'CRANBOURNE EAST', label: 'CRANBOURNE EAST' },
    { value: 'CRANBOURNE NORTH', label: 'CRANBOURNE NORTH' },
    { value: 'CRANBOURNE SOUTH', label: 'CRANBOURNE SOUTH' },
    { value: 'CRANBOURNE WEST', label: 'CRANBOURNE WEST' },
    { value: 'CROYDON', label: 'CROYDON' },
    { value: 'CROYDON HILLS', label: 'CROYDON HILLS' },
    { value: 'CROYDON NORTH', label: 'CROYDON NORTH' },
    { value: 'CROYDON SOUTH', label: 'CROYDON SOUTH' },
    { value: 'DALLAS', label: 'DALLAS' },
    { value: 'DANDENONG', label: 'DANDENONG' },
    { value: 'DANDENONG NORTH', label: 'DANDENONG NORTH' },
    { value: 'DANDENONG SOUTH', label: 'DANDENONG SOUTH' },
    { value: 'DEEPDENE', label: 'DEEPDENE' },
    { value: 'DEER PARK', label: 'DEER PARK' },
    { value: 'DERRIMUT', label: 'DERRIMUT' },
    { value: 'DIAMOND CREEK', label: 'DIAMOND CREEK' },
    { value: 'DOCKLANDS', label: 'DOCKLANDS' },
    { value: 'DONCASTER', label: 'DONCASTER' },
    { value: 'DONCASTER EAST', label: 'DONCASTER EAST' },
    { value: 'DONVALE', label: 'DONVALE' },
    { value: 'DOVETON', label: 'DOVETON' },
    { value: 'EAGLEMONT', label: 'EAGLEMONT' },
    { value: 'EAST MELBOURNE', label: 'EAST MELBOURNE' },
    { value: 'ELSTERNWICK', label: 'ELSTERNWICK' },
    { value: 'ELTHAM', label: 'ELTHAM' },
    { value: 'ELTHAM NORTH', label: 'ELTHAM NORTH' },
    { value: 'ELWOOD', label: 'ELWOOD' },
    { value: 'EMERALD', label: 'EMERALD' },
    { value: 'ENDEAVOUR HILLS', label: 'ENDEAVOUR HILLS' },
    { value: 'EPPING', label: 'EPPING' },
    { value: 'ESSENDON', label: 'ESSENDON' },
    { value: 'ESSENDON NORTH', label: 'ESSENDON NORTH' },
    { value: 'ESSENDON WEST', label: 'ESSENDON WEST' },
    { value: 'FAIRFIELD', label: 'FAIRFIELD' },
    { value: 'FAWKNER', label: 'FAWKNER' },
    { value: 'FERNTREE GULLY', label: 'FERNTREE GULLY' },
    { value: 'FITZROY', label: 'FITZROY' },
    { value: 'FITZROY NORTH', label: 'FITZROY NORTH' },
    { value: 'FLINDERS', label: 'FLINDERS' },
    { value: 'FOOTSCRAY', label: 'FOOTSCRAY' },
    { value: 'FOREST HILL', label: 'FOREST HILL' },
    { value: 'FRANKSTON', label: 'FRANKSTON' },
    { value: 'FRANKSTON NORTH', label: 'FRANKSTON NORTH' },
    { value: 'FRANKSTON SOUTH', label: 'FRANKSTON SOUTH' },
    { value: 'GARDENVALE', label: 'GARDENVALE' },
    { value: 'GLADSTONE PARK', label: 'GLADSTONE PARK' },
    { value: 'GLEN IRIS', label: 'GLEN IRIS' },
    { value: 'GLEN WAVERLEY', label: 'GLEN WAVERLEY' },
    { value: 'GLENROY', label: 'GLENROY' },
    { value: 'GREENSBOROUGH', label: 'GREENSBOROUGH' },
    { value: 'GREENVALE', label: 'GREENVALE' },
    { value: 'HALLAM', label: 'HALLAM' },
    { value: 'HAMPTON', label: 'HAMPTON' },
    { value: 'HAMPTON EAST', label: 'HAMPTON EAST' },
    { value: 'HAMPTON PARK', label: 'HAMPTON PARK' },
    { value: 'HAWTHORN', label: 'HAWTHORN' },
    { value: 'HAWTHORN EAST', label: 'HAWTHORN EAST' },
    { value: 'HEATHERTON', label: 'HEATHERTON' },
    { value: 'HEIDELBERG', label: 'HEIDELBERG' },
    { value: 'HEIDELBERG HEIGHTS', label: 'HEIDELBERG HEIGHTS' },
    { value: 'HEIDELBERG WEST', label: 'HEIDELBERG WEST' },
    { value: 'HILLSIDE', label: 'HILLSIDE' },
    { value: 'HOPPERS CROSSING', label: 'HOPPERS CROSSING' },
    { value: 'HUGHESDALE', label: 'HUGHESDALE' },
    { value: 'HUNTINGDALE', label: 'HUNTINGDALE' },
    { value: 'IVANHOE', label: 'IVANHOE' },
    { value: 'IVANHOE EAST', label: 'IVANHOE EAST' },
    { value: 'JACANA', label: 'JACANA' },
    { value: 'KEILOR', label: 'KEILOR' },
    { value: 'KEILOR DOWNS', label: 'KEILOR DOWNS' },
    { value: 'KEILOR EAST', label: 'KEILOR EAST' },
    { value: 'KEILOR PARK', label: 'KEILOR PARK' },
    { value: 'KEW', label: 'KEW' },
    { value: 'KEW EAST', label: 'KEW EAST' },
    { value: 'KEYSBOROUGH', label: 'KEYSBOROUGH' },
    { value: 'KILSYTH', label: 'KILSYTH' },
    { value: 'KILSYTH SOUTH', label: 'KILSYTH SOUTH' },
    { value: 'KINGSBURY', label: 'KINGSBURY' },
    { value: 'KINGSTON', label: 'KINGSTON' },
    { value: 'KINGSVILLE', label: 'KINGSVILLE' },
    { value: 'KNOXFIELD', label: 'KNOXFIELD' },
    { value: 'KOOYONG', label: 'KOOYONG' },
    { value: 'KURUNJANG', label: 'KURUNJANG' },
    { value: 'LALOR', label: 'LALOR' },
    { value: 'LILYDALE', label: 'LILYDALE' },
    { value: 'LOWER PLENTY', label: 'LOWER PLENTY' },
    { value: 'LYSTERFIELD', label: 'LYSTERFIELD' },
    { value: 'MACLEOD', label: 'MACLEOD' },
    { value: 'MALVERN', label: 'MALVERN' },
    { value: 'MALVERN EAST', label: 'MALVERN EAST' },
    { value: 'MARIBYRNONG', label: 'MARIBYRNONG' },
    { value: 'MELBOURNE', label: 'MELBOURNE' },
    { value: 'MELTON', label: 'MELTON' },
    { value: 'MELTON SOUTH', label: 'MELTON SOUTH' },
    { value: 'MELTON WEST', label: 'MELTON WEST' },
    { value: 'MENTONE', label: 'MENTONE' },
    { value: 'MERNDA', label: 'MERNDA' },
    { value: 'MIDDLE PARK', label: 'MIDDLE PARK' },
    { value: 'MILL PARK', label: 'MILL PARK' },
    { value: 'MITCHAM', label: 'MITCHAM' },
    { value: 'MOOLAP', label: 'MOOLAP' },
    { value: 'MOONEE PONDS', label: 'MOONEE PONDS' },
    { value: 'MOORABBIN', label: 'MOORABBIN' },
    { value: 'MOOROOLBARK', label: 'MOOROOLBARK' },
    { value: 'MORDIALLOC', label: 'MORDIALLOC' },
    { value: 'MOUNT ELIZA', label: 'MOUNT ELIZA' },
    { value: 'MOUNT WAVERLEY', label: 'MOUNT WAVERLEY' },
    { value: 'MULGRAVE', label: 'MULGRAVE' },
    { value: 'MURRUMBEENA', label: 'MURRUMBEENA' },
    { value: 'NARRE WARREN', label: 'NARRE WARREN' },
    { value: 'NARRE WARREN NORTH', label: 'NARRE WARREN NORTH' },
    { value: 'NARRE WARREN SOUTH', label: 'NARRE WARREN SOUTH' },
    { value: 'NEWPORT', label: 'NEWPORT' },
    { value: 'NIDDRIE', label: 'NIDDRIE' },
    { value: 'NOBLE PARK', label: 'NOBLE PARK' },
    { value: 'NOBLE PARK NORTH', label: 'NOBLE PARK NORTH' },
    { value: 'NORTHCOTE', label: 'NORTHCOTE' },
    { value: 'NORTH MELBOURNE', label: 'NORTH MELBOURNE' },
    { value: 'OAKLEIGH', label: 'OAKLEIGH' },
    { value: 'OAKLEIGH EAST', label: 'OAKLEIGH EAST' },
    { value: 'OAKLEIGH SOUTH', label: 'OAKLEIGH SOUTH' },
    { value: 'ORANGE GROVE', label: 'ORANGE GROVE' },
    { value: 'ORMOND', label: 'ORMOND' },
    { value: 'PARKDALE', label: 'PARKDALE' },
    { value: 'PARKVILLE', label: 'PARKVILLE' },
    { value: 'PASCOE VALE', label: 'PASCOE VALE' },
    { value: 'PINES FOREST', label: 'PINES FOREST' },
    { value: 'POINT COOK', label: 'POINT COOK' },
    { value: 'PORT MELBOURNE', label: 'PORT MELBOURNE' },
    { value: 'PRAHRAN', label: 'PRAHRAN' },
    { value: 'PRESTON', label: 'PRESTON' },
    { value: 'RAVENHALL', label: 'RAVENHALL' },
    { value: 'RICHMOND', label: 'RICHMOND' },
    { value: 'RINGWOOD', label: 'RINGWOOD' },
    { value: 'RINGWOOD EAST', label: 'RINGWOOD EAST' },
    { value: 'RINGWOOD NORTH', label: 'RINGWOOD NORTH' },
    { value: 'ROSANNA', label: 'ROSANNA' },
    { value: 'ROWVILLE', label: 'ROWVILLE' },
    { value: 'RUTHERFORD', label: 'RUTHERFORD' },
    { value: 'SANDRINGHAM', label: 'SANDRINGHAM' },
    { value: 'SCORESBY', label: 'SCORESBY' },
    { value: 'SEABROOK', label: 'SEABROOK' },
    { value: 'SEAFORD', label: 'SEAFORD' },
    { value: 'SEAHOLME', label: 'SEAHOLME' },
    { value: 'SOUTH YARRA', label: 'SOUTH YARRA' },
    { value: 'SOUTH MELBOURNE', label: 'SOUTH MELBOURNE' },
    { value: 'SOUTH MORANG', label: 'SOUTH MORANG' },
    { value: 'SOUTH KINGSVILLE', label: 'SOUTH KINGSVILLE' },
    { value: 'SOUTH WHARF', label: 'SOUTH WHARF' },
    { value: 'SPOTSWOOD', label: 'SPOTSWOOD' },
    { value: 'SPRINGVALE', label: 'SPRINGVALE' },
    { value: 'SPRINGVALE SOUTH', label: 'SPRINGVALE SOUTH' },
    { value: 'ST ALBANS', label: 'ST ALBANS' },
    { value: 'ST KILDA', label: 'ST KILDA' },
    { value: 'ST KILDA EAST', label: 'ST KILDA EAST' },
    { value: 'ST KILDA WEST', label: 'ST KILDA WEST' },
    { value: 'STRATHMORE', label: 'STRATHMORE' },
    { value: 'SUNBURY', label: 'SUNBURY' },
    { value: 'SUNSHINE', label: 'SUNSHINE' },
    { value: 'SUNSHINE NORTH', label: 'SUNSHINE NORTH' },
    { value: 'SUNSHINE WEST', label: 'SUNSHINE WEST' },
    { value: 'SURREY HILLS', label: 'SURREY HILLS' },
    { value: 'SYDENHAM', label: 'SYDENHAM' },
    { value: 'TARNEIT', label: 'TARNEIT' },
    { value: 'TEMPLESTOWE', label: 'TEMPLESTOWE' },
    { value: 'TEMPLESTOWE LOWER', label: 'TEMPLESTOWE LOWER' },
    { value: 'THORNBURY', label: 'THORNBURY' },
    { value: 'TOORAK', label: 'TOORAK' },
    { value: 'TRAVANCORE', label: 'TRAVANCORE' },
    { value: 'TULLAMARINE', label: 'TULLAMARINE' },
    { value: 'VERMONT', label: 'VERMONT' },
    { value: 'VERMONT SOUTH', label: 'VERMONT SOUTH' },
    { value: 'VIEWBANK', label: 'VIEWBANK' },
    { value: 'WANTIRNA', label: 'WANTIRNA' },
    { value: 'WANTIRNA SOUTH', label: 'WANTIRNA SOUTH' },
    { value: 'WARRANDYTE', label: 'WARRANDYTE' },
    { value: 'WATERWAYS', label: 'WATERWAYS' },
    { value: 'WERRIBEE', label: 'WERRIBEE' },
    { value: 'WEST FOOTSCRAY', label: 'WEST FOOTSCRAY' },
    { value: 'WEST MELBOURNE', label: 'WEST MELBOURNE' },
    { value: 'WESTMEADOWS', label: 'WESTMEADOWS' },
    { value: 'WHEELERS HILL', label: 'WHEELERS HILL' },
    { value: 'WILLIAMSTOWN', label: 'WILLIAMSTOWN' },
    { value: 'WINDSOR', label: 'WINDSOR' },
    { value: 'WOLLERT', label: 'WOLLERT' },
    { value: 'WYNDHAM VALE', label: 'WYNDHAM VALE' },
    { value: 'YARRAVILLE', label: 'YARRAVILLE' }
];


// Helper function to determine the color based on risk level
const getRiskLevelColor = (riskLevel) => {
    if (riskLevel === 'High') {
        return { color: 'red', fontWeight: 'bold' };
    } else if (riskLevel === 'Moderate') {
        return { color: 'orange', fontWeight: 'bold' };
    } else {
        return { color: 'green', fontWeight: 'bold' };
    }
};


const questions = [
    {
        id: 1,
        question: "What is your current area?",
        type: "dropdown",
        options: optionsList
    },
    {
        id: 2,
        question: "When are you planning to travel?",
        type: "radio",
        options: ["Day", "Evening", "Night"]
    },
    {
        id: 3,
        question: "What is your current transportation?",
        type: "radio",
        options: ["Walk", "Public Transportation", "Car"]
    }
];

const Chatbox = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isIntro, setIsIntro] = useState(true);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [responses, setResponses] = useState({});
    const [showAdvice, setShowAdvice] = useState(false);
    const [advice, setAdvice] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const handleStart = () => {
        setIsIntro(false);
    };

    const handleChange = (e) => {
        const question = questions[currentQuestionIndex];

        if (question.type === "dropdown") {
            setResponses({
                ...responses,
                [question.id]: e ? e.value : ''
            });
        } else {
            const { value } = e.target;
            setResponses({
                ...responses,
                [question.id]: value
            });
        }

        setErrorMessage('');
    };

    const handleNext = () => {
        const question = questions[currentQuestionIndex];
        if (!responses[question.id]) {
            setErrorMessage('*Please select an option before proceeding.');
            return;
        }
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
            const generatedAdvice = generateAdvice(responses);
            setAdvice(generatedAdvice);
            setShowAdvice(true);
        }
    };

    const handlePrevious = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    };

    const handleRestart = () => {
        setResponses({});
        setCurrentQuestionIndex(0);
        setShowAdvice(false);
        setIsIntro(true);
        setErrorMessage('');
    };

    const toggleChatbox = () => {
        setIsOpen(!isOpen);
        if (isOpen) {
            handleRestart();
        }
    };

    const renderIntro = () => {
        return (
            <div style={styles.introContainer}>
                <h2 style={styles.introTitle}>Welcome to the Safety Advice Questionnaire</h2>
                <p style={styles.introText}>
                    This brief questionnaire will help you get personalized safety advice based on your current situation.
                    It will only take a few moments to complete. Let's get started!
                </p>
                <button onClick={handleStart} style={styles.startButton}>Start</button>
            </div>
        );
    };

    const renderCurrentQuestion = () => {
        const question = questions[currentQuestionIndex];
        const questionNumber = currentQuestionIndex + 1;
        const totalQuestions = questions.length;

        return (
            <div style={styles.questionCard}>
                <div style={styles.questionHeader}>
                    <span style={styles.questionCounter}>{questionNumber}/{totalQuestions}</span>
                    <button onClick={toggleChatbox} style={styles.closeButton}>Close</button>
                </div>
                <label style={styles.label}>{question.question}</label><br />
                {question.type === "dropdown" ? (
                    <Select
                        name={question.id.toString()}
                        value={question.options.find(option => option.value === responses[question.id]) || ''}
                        onChange={handleChange}
                        options={question.options}
                        placeholder="Select or Enter..."
                        isClearable
                    />
                ) : (
                    question.options.map((option, index) => (
                        <label key={index} style={styles.option}>
                            <input
                                type="radio"
                                name={question.id}
                                value={option}
                                checked={responses[question.id] === option}
                                onChange={handleChange}
                                style={styles.radio}
                            />
                            {option}
                        </label>
                    ))
                )}
                {errorMessage && <p style={styles.error}>{errorMessage}</p>}
                <div style={currentQuestionIndex > 0 ? styles.buttonContainer : styles.buttonContainerRight}>
                    {currentQuestionIndex > 0 && (
                        <button onClick={handlePrevious} style={styles.prevButton}>Previous</button>
                    )}
                    <button onClick={handleNext} style={styles.button}>
                        {currentQuestionIndex === questions.length - 1 ? "Get Advice" : "Next"}
                    </button>
                </div>
            </div>
        );
    };

    const renderSummary = () => {
        return (
            <div style={styles.summaryContainer}>
                <h3 style={styles.summaryTitle}>Your Selections:</h3>
                <ul style={styles.summaryList}>
                    {questions.map((question) => (
                        <li key={question.id} style={styles.summaryItem}>
                            <strong>{question.question}</strong>: {responses[question.id]}
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    const renderAdvice = () => {
        return (
            <div style={styles.adviceContainer}>
                <h2 style={styles.adviceTitle}>Your Personalized Safety Advice:</h2>
                <ul style={styles.adviceList}>
                    {advice.map((item, index) => (
                        <li key={index} style={getRiskLevelColor(item.riskLevel)}>
                            {item.text}
                        </li>
                    ))}
                </ul>
                <div style={styles.buttonContainerCentered}>
                    <button onClick={handleRestart} style={styles.button}>Get Another</button>
                </div>
            </div>
        );
    };

    return (
        <div>
            <div style={styles.chatButton} onClick={toggleChatbox}>
                <img src="/chatbot.png" alt="Chat Icon" style={styles.chatIcon}/>
            </div>
            {isOpen && (
                <div style={styles.chatbox}>
                    {showAdvice ? (
                        <div>
                            {renderSummary()}
                            {renderAdvice()}
                        </div>
                    ) : isIntro ? (
                        renderIntro()
                    ) : (
                        renderCurrentQuestion()
                    )}
                </div>
            )}
        </div>
    );
};


const styles = {
    chatButton: {
        position: 'fixed',
        bottom: '80px',
        right: '20px',
        backgroundColor: '#007bff',
        color: 'white',
        borderRadius: '50%',
        width: '60px',
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        zIndex: 2,
    },
    chatIcon: {
        width: '60px',
        height: '60px',
    },

    '@media (max-width: 768px)': {
        chatButton: {
            width: '20px',
            height: '20px',
            bottom: '20px',
            right: '10px',
        },
        chatIcon: {
            width: '20px',
            height: '20px',
        },
    },
    chatbox: {
        position: 'fixed',
        bottom: '150px',
        right: '20px',
        width: '300px',
        maxHeight: '400px',
        backgroundColor: '#fff7e6',
        borderRadius: '12px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        padding: '20px',
        zIndex: 2,
        overflowY: 'auto',
    },
    title: {
        fontSize: '20px',
        textAlign: 'center',
        marginBottom: '15px',
        color: '#333',
        fontFamily: "'Georgia', serif",
    },
    introContainer: {
        textAlign: 'center',
    },
    introTitle: {
        fontSize: '18px',
        marginBottom: '10px',
        color: '#333',
    },
    introText: {
        fontSize: '16px',
        color: '#555',
        marginBottom: '20px',
    },
    startButton: {
        padding: '10px 20px',
        fontSize: '16px',
        backgroundColor: '#007bff',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    questionCard: {
        backgroundColor: '#ffffff',
        borderRadius: '8px',
        padding: '10px',
        marginBottom: '10px',
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
        borderLeft: '5px solid #ffab66',
    },
    questionHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '10px',
    },
    questionCounter: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#555',
    },
    label: {
        fontSize: '16px',
        fontWeight: 'bold',
        fontFamily: "'Arial', sans-serif",
        color: '#d9534f',
        marginBottom: '8px',
        display: 'block',
    },
    option: {
        display: 'block',
        marginBottom: '10px',
        fontSize: '16px',
        color: '#333',
    },
    radio: {
        marginRight: '10px',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '10px',
    },
    buttonContainerRight: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '10px',
    },
    buttonContainerCentered: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',
    },
    button: {
        padding: '8px 12px',
        fontSize: '14px',
        backgroundColor: '#ff704d',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    prevButton: {
        padding: '8px 12px',
        fontSize: '14px',
        backgroundColor: '#6c757d',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    adviceContainer: {
        marginTop: '20px',
    },
    adviceTitle: {
        fontSize: '18px',
        marginBottom: '10px',
        color: '#333',
    },
    adviceList: {
        listStyleType: 'disc',
        paddingLeft: '20px',
    },
    summaryContainer: {
        marginBottom: '20px',
    },
    summaryTitle: {
        fontSize: '16px',
        color: '#333',
        marginBottom: '10px',
    },
    summaryList: {
        listStyleType: 'none',
        paddingLeft: '0',
    },
    summaryItem: {
        fontSize: '14px',
        marginBottom: '5px',
        color: '#555',
    },
    error: {
        color: 'red',
        fontSize: '14px',
        marginTop: '10px',
    },
    closeButton: {
        backgroundColor: 'transparent',
        border: 'none',
        fontSize: '14px',
        color: '#d9534f',
        cursor: 'pointer',
        marginLeft: 'auto',
        fontWeight: 'bold',
    },
};

export default Chatbox;