import React from 'react';
import HeroSection from './HeroSection';
import FeaturedServices from './FeaturedServices';
import AboutSection from './AboutSection';

function LandingPage() {

    const landingPageStyle = {
        fontFamily: 'Arial, sans-serif',
        color: '#333',
      
    };

    return (
        <div style={landingPageStyle}>
            <HeroSection />
            <FeaturedServices />
            <AboutSection />
        </div>
    );
}



export default LandingPage;
