import React, { useEffect, useState } from "react";
import { GoogleMap, LoadScript, InfoWindow } from "@react-google-maps/api";
import axios from "axios";
import PollenDataDisplay from "./PollenDataDisplay";
import "./styles.css"; // Import the styles

const PollenHeatMap = () => {
  const [map, setMap] = useState(null);
  const [geoJsonData, setGeoJsonData] = useState(null);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [infoWindowPosition, setInfoWindowPosition] = useState(null);
  const [pollenData, setPollenData] = useState(null);
  const [selectedDay, setSelectedDay] = useState(0);

  const apiUrl = "http://54.87.57.66:3000/api/geojson"; // Polygon backend API URL

  useEffect(() => {
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => setGeoJsonData(data))
      .catch((err) => console.error("Error fetching GeoJSON:", err));

    fetch("/locations.json") // Use actual path
      .then((response) => response.json())
      .then((data) => setLocations(data))
      .catch((err) => console.error("Error fetching locations:", err));
  }, [apiUrl]);

  const onLoad = (mapInstance) => {
    setMap(mapInstance);
  };

  useEffect(() => {
    if (map && geoJsonData) {
      map.data.addGeoJson(geoJsonData);

      map.data.setStyle({
        fillColor: "transparent",
        fillOpacity: 0.5,
        strokeColor: "transparent",
        strokeWeight: 1,
      });

      const bounds = new window.google.maps.LatLngBounds();
      map.data.forEach((feature) => {
        const geometry = feature.getGeometry();
        if (geometry) {
          geometry.forEachLatLng((latLng) => bounds.extend(latLng));
        }
      });
      map.fitBounds(bounds);
    }
  }, [map, geoJsonData]);

  useEffect(() => {
    const handleResize = () => {
      if (map) {
        // Trigger resize for the map after the container size changes
        setTimeout(() => {
          window.google.maps.event.trigger(map, "resize");
          if (map) {
            map.setZoom(map.getZoom()); // Refresh zoom level to force redraw
          }
        }, 200); // Add a delay to allow the map container to resize
      }
    };
  
    window.addEventListener("resize", handleResize);
  
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [map]);

  const handleLocationChange = async (event) => {
    const selectedLoc = locations.find(
      (loc) => loc.loc_name === event.target.value
    );

    if (selectedLoc) {
      // Reset the infoWindow to prevent overlap on new selection
      setInfoWindowPosition(null);
      setSelectedLocation(selectedLoc);

      if (map) {
        const { latitude, longitude } = selectedLoc;

        // Move map and set info window position after setting the location
        map.panTo({ lat: latitude, lng: longitude });
        map.setZoom(12);

        // Delay setting the info window to ensure the map has finished panning
        setTimeout(() => {
          setInfoWindowPosition({ lat: latitude, lng: longitude });
        }, 500);

        const pollenApiUrl = `http://54.87.57.66:3000/pollen/${latitude}/${longitude}`;
        try {
          const response = await axios.get(pollenApiUrl);
          setPollenData(response.data);
        } catch (error) {
          console.error("Error fetching pollen data:", error);
        }
      }
    }
  };

  // Function to determine InfoWindow color based on UPI
  const getInfoWindowColor = (upi) => {
    if (upi >= 8) return "#FF0000"; // High alert (Red)
    if (upi >= 5) return "#FFA500"; // Medium alert (Orange)
    return "#58855c"; // Low alert (Green)
  };

  return (
    <div className="container">
      <div className="map-container">
        <LoadScript
          googleMapsApiKey="AIzaSyANZLV0u80m8vu8Fj6gvPAcz7eEwYffy64"
        >
          <GoogleMap
            mapContainerStyle={{ height: "100%", width: "100%" }}
            center={{ lat: -37.8136, lng: 144.9631 }} // Default center (Melbourne)
            zoom={15}
            onLoad={onLoad}
          >
            {infoWindowPosition && selectedLocation && pollenData && (
            <InfoWindow position={infoWindowPosition}>
              <div
                style={{
                  backgroundColor: getInfoWindowColor(
                    pollenData.dailyInfo[selectedDay].pollenTypeInfo[0]?.indexInfo.value
                  ),
                  padding: "10px",
                  borderRadius: "5px",
                  color: "#fff",
                }}
              >
                <h4>{selectedLocation.loc_name}</h4>
                <p>
                  Pollen Level:{" "}
                  {pollenData.dailyInfo[selectedDay].pollenTypeInfo[0]?.indexInfo.category} <br />
                  UPI: {pollenData.dailyInfo[selectedDay].pollenTypeInfo[0]?.indexInfo.value}
                </p>
              </div>
            </InfoWindow>
              )}
          </GoogleMap>
        </LoadScript>
      </div>

      <div className="info-container">
        <h3>Select a Location
          <p>Choose a neighborhood to get real-time pollen forecasts tailored to your area.</p>
        </h3>
        <select onChange={handleLocationChange}>
          <option value="">Select a Location</option>
          {locations.map((loc, index) => (
            <option key={index} value={loc.loc_name}>
              {loc.loc_name}
            </option>
          ))}
        </select>

        {/* Show welcome content when no location is selected */}
        {!selectedLocation && (
          <div className="placeholder-content">
            <h2>Stay Informed for a Healthier You 🌱</h2>
            <div className="support-info">
              <p>
              Planning time outdoors? Our tool offers up-to-date pollen data to help you avoid high-risk areas. 
              Select your location for personalized recommendations on managing allergies and staying comfortable, 
              no matter the pollen count. Protect your health and enjoy your surroundings with confidence.
              </p>
            </div>
            <img
              src={`${process.env.PUBLIC_URL}/wellbeing.jpg`}
              alt="Placeholder" // Placeholder image or custom map/illustration
              style={{ width: "50%", height: "50%",  maxWidth: "400px", marginTop: "10px" }}
            />
          </div>
        )}

        {/* When location is selected, show the pollen data */}
        {pollenData && selectedLocation && (
          <>
            {pollenData.dailyInfo && pollenData.dailyInfo.length > 1 && (
              <div style={{ marginTop: "10px" }}>
                {pollenData.dailyInfo.map((dayInfo, idx) => (
                  <button
                    key={idx}
                    onClick={() => setSelectedDay(idx)}
                    className={`button ${
                      selectedDay === idx ? "selected" : ""
                    }`} // Apply 'selected' class when the button is clicked
                  >
                    {`${dayInfo.date.day}/${dayInfo.date.month}/${dayInfo.date.year}`}
                  </button>
                ))}
              </div>
            )}

            <div style={{ marginTop: "20px" }}>
              <h3>
                Pollen Information for{" "}
                {selectedLocation.loc_name || "this location"}
              </h3>
              <PollenDataDisplay
                pollenData={pollenData}
                selectedDay={selectedDay}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PollenHeatMap;
