import React, { useEffect, useState } from "react";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import Legend from "./Legend";
import { CategoryScale } from 'chart.js'; 
import Chart from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
Chart.register(CategoryScale);

const Safety = () => {
  const [map, setMap] = useState(null);
  const [geoJsonData, setGeoJsonData] = useState(null);
  const [crimeData, setCrimeData] = useState(null);
  const [crimeTypeData, setCrimeTypeData] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [chartData, setChartData] = useState({});
  const apiUrl = process.env.REACT_APP_API_URL;
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  useEffect(() => {
    if (map) {
      // Fetch the GeoJSON file
      fetch('/localities.geojson')
        .then((response) => response.json())
        .then((data) => {
          const correctedGeoJsonData = {
            ...data,
            features: data.features.map((feature) => ({
              ...feature,
              geometry: {
                ...feature.geometry,
                coordinates: feature.geometry.coordinates.map(polygon =>
                  polygon.map(coord =>
                    Array.isArray(coord[0])
                      ? coord.map(([lat, lng]) => [lng, lat])
                      : [coord[1], coord[0]]
                  )
                ),
              },
            })),
          };
  
          console.log("Corrected GeoJSON data:", correctedGeoJsonData);
  
          // Fetch crime data from the server
          fetch(`${apiUrl}/crime-data`)
            .then((response) => response.json())
            .then((crimeData) => {
              setCrimeData(crimeData);
  
              // Map crime data to localities
              const crimeMap = new Map(crimeData.map(crime => [crime.Suburb_Town_Name, crime.total_crime_count]));
  
              // Merge crime data into GeoJSON data
              const geoJsonWithCrimeData = {
                ...correctedGeoJsonData,
                features: correctedGeoJsonData.features.map(feature => {
                  const localityName = feature.properties.LOC_NAME;
                  const crimeCount = crimeMap.get(localityName) || 0;
                  return {
                    ...feature,
                    properties: {
                      ...feature.properties,
                      crimeCount: crimeCount
                    }
                  };
                })
              };
  
              // Add the merged GeoJSON with crime data to the map
              map.data.addGeoJson(geoJsonWithCrimeData);
  
              // Style the polygons based on crime count
              map.data.setStyle((feature) => {
                const count = feature.getProperty('crimeCount');
                return {
                  fillColor: getColor(count),
                  strokeWeight: 1,
                  fillOpacity: 0.75,
                };
              });
  
              // Fit the map bounds to the data
              const bounds = new window.google.maps.LatLngBounds();
              map.data.forEach((feature) => {
                const geometry = feature.getGeometry();
                if (geometry) {
                  geometry.forEachLatLng((latLng) => {
                    bounds.extend(latLng);
                  });
                }
              });
              map.fitBounds(bounds);
  
              // Fetch the crime type data for the detailed breakdown
              fetch(`${apiUrl}/crime-type`)
                .then((response) => response.json())
                .then((crimeTypeData) => {
                  setCrimeTypeData(crimeTypeData);
  
                  // Create an info window to display the locality name and crime count
                  const infoWindow = new window.google.maps.InfoWindow();
  
                  // Add a click listener to each polygon
                  map.data.addListener("click", (event) => {
                    const localityName = event.feature.getProperty("LOC_NAME");
                    const crimeCount = event.feature.getProperty("crimeCount");
                    const position = event.latLng;
  
                    // Set the content and position of the info window
                    infoWindow.setContent(`<div><strong>${localityName}</strong><br/>Total Crimes: ${crimeCount}</div>`);
                    infoWindow.setPosition(position);
                    infoWindow.open(map);
  
                    // Set selected region and prepare chart data for offense types
                    const regionData = crimeTypeData.find(data => data.locality === localityName);
                    if (regionData) {
                      setSelectedRegion(localityName);
                      setChartData({
                        labels: Object.keys(regionData.offenses),
                        datasets: [{
                          label: 'Offense Types',
                          data: Object.values(regionData.offenses),
                          backgroundColor: ['#FF7F50', '#FFA07A', '#87CEEB', '#4682B4', '#B0C4DE', '#6495ED'],
                        }],
                      });
  
                      // Log chart data to ensure it is correct
                      console.log("Chart Data:", {
                        labels: Object.keys(regionData.offenses),
                        datasets: [{
                          label: 'Offense Types',
                          data: Object.values(regionData.offenses),
                          backgroundColor: ['#FF7F50', '#FFA07A', '#87CEEB', '#4682B4', '#B0C4DE', '#6495ED'],
                        }]
                      });
                    }
                  });
                })
                .catch((error) => {
                  console.error("Error fetching crime type data:", error);
                });
            })
            .catch((error) => {
              console.error("Error fetching crime data:", error);
            });
        })
        .catch((error) => {
          console.error("Error loading the GeoJSON file:", error);
        });
    }
  }, [map]);

  const getColor = (crimeCount) => {
    if (crimeCount > 10000) return '#FF7F50';
    if (crimeCount > 5000) return '#FFA07A';
    if (crimeCount > 500) return '#87CEEB';
    if (crimeCount > 100) return '#4682B4';
    return '#B0C4DE';
  };

  const onLoad = (mapInstance) => {
    setMap(mapInstance);
  };

  return (
    <div style={styles.safetyPage}>
      <div style={styles.mapHeader}>
        <h2>Safety of Migrant Women in Melbourne</h2>
        <p>Explore the safety data across different localities in Melbourne to understand safety levels.</p>
        <p><strong>Tip:</strong> Click on any suburb to see a detailed breakdown of the crime types.</p>
      </div>
      <div style={styles.mapAndChartContainer}>
        <div style={styles.mapContainer}>
          <LoadScript googleMapsApiKey={googleMapsApiKey}>
            <GoogleMap
              mapContainerStyle={styles.mapContainerStyle}
              center={{ lat: -37.8136, lng: 144.9631 }} // Default center (Melbourne)
              zoom={10}
              onLoad={onLoad}
            />
             {map && <Legend map={map} />}
          </LoadScript>
        </div>
        <div style={styles.chartContainer}>
          {selectedRegion && (
            <>
              <h2 style={styles.chartTitle}>Offense Distribution for {selectedRegion}</h2>
              <Bar data={chartData} options={chartOptions} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const chartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    tooltip: {
      callbacks: {
        label: (tooltipItem) => `${tooltipItem.dataset.label}: ${tooltipItem.raw}`,
      },
    },
  },
};

const styles = {
  safetyPage: {
    padding: '10px',
    fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif',
    backgroundColor: '#f9f9f9',
    maxWidth: '1400px', // Limit max width for large screens
    margin: '0 auto', // Center content on large screens
  },
  mapHeader: {
    textAlign: 'center',
    marginBottom: '20px',
    color: '#333',
  },
  mapAndChartContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'row', // Default to side-by-side layout
    flexWrap: 'wrap', // Wrap to vertical layout on smaller screens
    gap: '20px',
  },
  mapContainer: {
    flex: '1 1 80%', // Allow the map to take up 50% of the space
    minWidth: '300px',
    maxWidth: '1000px', // Limit map width on larger screens
    paddingBottom: '56.25%', // Maintain a 16:9 aspect ratio
    position: 'relative',
    border: '2px solid #ccc',
    borderRadius: '10px',
    overflow: 'hidden',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  mapContainerStyle: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
  },
  chartContainer: {
    flex: '1 1 20%', // Allow the chart to take up 20% of the space
    minWidth: '300px',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  chartTitle: {
    marginBottom: '15px',
    color: '#333',
  },
};


export default Safety;
