const generateAdvice = (responses) => {
    let advice = [];

    // High crime area list
    const highCrimeAreas = [
        "BROADMEADOWS", "CRANBOURNE", "DANDENONG", "DOCKLANDS", "EPPING",
        "FITZROY", "FOOTSCRAY", "FRANKSTON", "HOPPERS CROSSING", "MELBOURNE",
        "NARRE WARREN", "NOBLE PARK", "PRESTON", "RICHMOND", "RINGWOOD",
        "SOUTH YARRA", "SPRINGVALE", "ST ALBANS", "ST KILDA", "SUNSHINE", "WERRIBEE"
    ];

    // Medium crime area list
    const mediumCrimeAreas = [
        "ABBOTSFORD", "ALBERT PARK", "ALPHINGTON", "ARMADALE", "ASCOT VALE",
        "ASHBURTON", "ASHWOOD", "ASPENDALE", "AVONDALE HEIGHTS", "BALACLAVA",
        "BALWYN", "BALWYN NORTH", "BAYSWATER", "BAYSWATER NORTH", "BEACONSFIELD",
        "BEAUMARIS", "BENTLEIGH", "BENTLEIGH EAST", "BERWICK", "BLACKBURN",
        "BLACKBURN NORTH", "BLACKBURN SOUTH", "BONBEACH", "BOX HILL", "BOX HILL NORTH",
        "BOX HILL SOUTH", "BRAYBROOK", "BRIGHTON", "BRIGHTON EAST", "BROOKLYN",
        "BRUNSWICK", "BRUNSWICK EAST", "BRUNSWICK WEST", "BULLEEN", "BUNDOORA",
        "BURWOOD", "BURWOOD EAST", "CAMBERWELL", "CAMPBELLFIELD", "CARLTON",
        "CARLTON NORTH", "CARNEGIE", "CAROLINE SPRINGS", "CARRUM", "CARRUM DOWNS",
        "CAULFIELD", "CAULFIELD EAST", "CAULFIELD NORTH", "CAULFIELD SOUTH",
        "CHADSTONE", "CHELTENHAM", "CHIRNSIDE PARK", "CLARINDA", "CLAYTON",
        "CLIFTON HILL", "COBURG", "COBURG NORTH", "COLLINGWOOD", "COOLAROO",
        "CRANBOURNE EAST", "CRANBOURNE NORTH", "CRANBOURNE WEST", "CROYDON",
        "DALLAS", "DANDENONG NORTH", "DANDENONG SOUTH", "DEER PARK", "DERRIMUT",
        "DIAMOND CREEK", "DONCASTER", "DONCASTER EAST", "DONVALE", "DOVETON",
        "EAST MELBOURNE", "ELSTERNWICK", "ELTHAM", "ELWOOD", "ENDEAVOUR HILLS",
        "ESSENDON", "FAIRFIELD", "FAWKNER", "FERNTREE GULLY", "FITZROY NORTH",
        "FOREST HILL", "FRANKSTON NORTH", "FRANKSTON SOUTH", "GLADSTONE PARK",
        "GLEN IRIS", "GLEN WAVERLEY", "GLENROY", "GREENSBOROUGH", "GREENVALE",
        "HALLAM", "HAMPTON", "HAMPTON EAST", "HAMPTON PARK", "HAWTHORN",
        "HAWTHORN EAST", "HEATHERTON", "HEIDELBERG", "HEIDELBERG HEIGHTS",
        "HEIDELBERG WEST", "HILLSIDE", "HUGHESDALE", "IVANHOE", "JACANA",
        "KEILOR", "KEILOR DOWNS", "KEILOR EAST", "KEILOR PARK", "KEW",
        "KEW EAST", "KEYSBOROUGH", "KILSYTH", "KINGSBURY", "KINGSVILLE",
        "KNOXFIELD", "KURUNJANG", "LALOR", "LILYDALE", "MACLEOD", "MALVERN",
        "MALVERN EAST", "MARIBYRNONG", "MELTON", "MELTON SOUTH", "MELTON WEST",
        "MENTONE", "MERNDA", "MILL PARK", "MITCHAM", "MOONEE PONDS", "MOORABBIN",
        "MOOROOLBARK", "MORDIALLOC", "MOUNT ELIZA", "MOUNT WAVERLEY", "MULGRAVE",
        "MURRUMBEENA", "NARRE WARREN NORTH", "NARRE WARREN SOUTH", "NEWPORT",
        "NOBLE PARK NORTH", "NORTH MELBOURNE", "NORTHCOTE", "OAKLEIGH", "OAKLEIGH EAST",
        "OAKLEIGH SOUTH", "ORMOND", "PARKDALE", "PARKVILLE", "PASCOE VALE", "POINT COOK",
        "PORT MELBOURNE", "PRAHRAN", "RINGWOOD EAST", "ROSANNA", "ROWVILLE",
        "SANDRINGHAM", "SCORESBY", "SOUTH MELBOURNE", "SOUTH MORANG", "ST KILDA EAST",
        "STRATHMORE", "SUNBURY", "SUNSHINE NORTH", "SUNSHINE WEST", "SURREY HILLS",
        "SYDENHAM", "TARNEIT", "TEMPLESTOWE", "TEMPLESTOWE LOWER", "THORNBURY", "TOORAK",
        "TULLAMARINE", "VERMONT", "VERMONT SOUTH", "WANTIRNA", "WANTIRNA SOUTH",
        "WEST FOOTSCRAY", "WEST MELBOURNE", "WESTMEADOWS", "WILLIAMSTOWN", "WINDSOR",
        "WOLLERT", "WYNDHAM VALE", "YARRAVILLE"
    ];

    // Low crime area list
    const lowCrimeAreas = [
        "ASPENDALE GARDENS", "ATTWOOD", "BELLFIELD", "BLACK ROCK", "BRIAR HILL",
        "BURNLEY", "CANTERBURY", "CRANBOURNE SOUTH", "CROYDON HILLS", "CROYDON NORTH",
        "CROYDON SOUTH", "DEEPDENE", "EAGLEMONT", "ELTHAM NORTH", "EMERALD",
        "ESSENDON NORTH", "ESSENDON WEST", "FLINDERS", "GARDENVALE", "HUNTINGDALE",
        "IVANHOE EAST", "JACANA", "KILSYTH SOUTH", "KINGSVILLE", "KOOYONG",
        "LOWER PLENTY", "LYSTERFIELD", "MIDDLE PARK", "NIDDRIE", "RINGWOOD NORTH",
        "SEABROOK", "SEAHOLME", "SOUTH KINGSVILLE", "SPOTSWOOD", "ST KILDA WEST",
        "VIEWBANK", "WARRANDYTE", "WATERWAYS"
    ];

    // Risk level based on area
    if (highCrimeAreas.includes(responses["1"])) {
        advice.push({ text: "The area you selected is known for high crime rates. It's advisable to avoid walking alone at night and to be extra vigilant.", riskLevel: "High" });
        if (responses["2"] === "Night") {
            advice.push({ text: "Since you're traveling at night in a high crime area, try to stay on well-lit streets and avoid isolated areas. Consider using a ride-sharing service instead of walking.", riskLevel: "High" });
        }
    } else if (mediumCrimeAreas.includes(responses["1"])) {
        advice.push({ text: "The area you selected has a moderate crime rate. Be cautious, especially during the evening and night.", riskLevel: "Moderate" });
        if (responses["2"] === "Evening") {
            advice.push({ text: "During the evening, avoid poorly lit areas and consider carrying a personal safety device.", riskLevel: "Moderate" });
        }
    } else if (lowCrimeAreas.includes(responses["1"])) {
        advice.push({ text: "The area you selected is known for low crime rates. Standard safety precautions should suffice.", riskLevel: "Low" });
    }

    // Provide advice based on transportation mode
    if (responses["3"] === "Walk") {
        advice.push({ text: "While walking, always stay aware of your surroundings. Avoid distractions like headphones, and if possible, walk with a friend.", riskLevel: "Moderate" });
    } else if (responses["3"] === "Public Transportation") {
        advice.push({ text: "When using public transportation, keep your belongings close to you and stay alert, especially in crowded areas.", riskLevel: "Moderate" });
    } else if (responses["3"] === "Car") {
        advice.push({ text: "If driving, ensure your car is locked at all times, and avoid leaving valuables in plain sight.", riskLevel: "Low" });
    }

    return advice;
};

export default generateAdvice;