import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Youtube.css';

const YOUTUBE_API_KEY = 'AIzaSyDIEFwEpyIhHslU3rTrXfFiwWfgKmjD9Uc';

const YouTubeVideos = ({ query }) => {
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (query) {
            fetchVideos(query);
        }
    }, [query]);

    const fetchVideos = async (searchQuery) => {
        try {
            const response = await axios.get('https://www.googleapis.com/youtube/v3/search', {
                params: {
                    part: 'snippet',
                    maxResults: 5,
                    q: searchQuery,
                    type: 'video',
                    key: YOUTUBE_API_KEY
                }
            });
            setVideos(response.data.items);
        } catch (error) {
            console.error('Error fetching YouTube videos:', error);
        } finally {
            setLoading(false);
        }
    };

    if (loading) return <div className="loading">Loading...</div>;

    return (
        <div className="youtube-videos">
            {videos.length > 0 ? (
                <div className="video-container">
                    {videos.map((video) => (
                        <div key={video.id.videoId} className="video-item">
                            <a href={`https://www.youtube.com/watch?v=${video.id.videoId}`} target="_blank" rel="noopener noreferrer">
                                <img src={video.snippet.thumbnails.medium.url} alt={video.snippet.title} className="thumbnail" />
                                <div className="video-title">{video.snippet.title}</div>
                            </a>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="no-videos">No videos found</div>
            )}
        </div>
    );
};

export default YouTubeVideos;
