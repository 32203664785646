import React from 'react';
import './FeaturedServices.css';

function FeaturedServices() {
    return (
        <section className="services-section">
            <div className="container">
                <div className="service">
                    <div className="service-content">
                        <div className="icon-container">
                            <img src={`${process.env.PUBLIC_URL}/map_icon.png`} alt="Icon 1" className="service-icon" />
                        </div>
                        <div className="text-content">
                            <h3>Pollen Data Insights</h3>
                            <p> Get insights about the pollen levels in your area. Explore now!</p>
                            <a href="/safety" className="learn-more">Learn More <span>&rarr;</span></a>
                        </div>
                    </div>
                </div>
                
                <div className="service">
                    <div className="service-content">
                        <div className="icon-container">
                            <img src={`${process.env.PUBLIC_URL}/knowledge_icon.png`} alt="Icon 3" className="service-icon" />
                        </div>
                        <div className="text-content">
                            <h3>Living in Melbourne Guide</h3>
                            <p>Access essential information, guides, and resources to ease your transition and integrate smoothly into your new community.</p>
                            <a href="/knowledgebase" className="learn-more">Learn More <span>&rarr;</span></a>
                        </div>
                    </div>
                </div>
                <div className="service">
                    <div className="service-content">
                        <div className="icon-container">
                            <img src={`${process.env.PUBLIC_URL}/faq_icon.png`} alt="Icon 2" className="service-icon" />
                        </div>
                        <div className="text-content">
                            <h3>FAQ</h3>
                            <p>Answers about Travel Safety, Health and well-being, home safety and much more..</p>
                            <a href="/faq" className="learn-more">Learn More <span>&rarr;</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FeaturedServices;