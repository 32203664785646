import React from 'react';
import './styles.css'; // Import the styles

const PollenDataDisplay = ({ pollenData, selectedDay }) => {
  if (!pollenData) return null;

  const dayInfo = pollenData.dailyInfo[selectedDay];

  return (
    <div className="pollen-info-card">
      <h4>Date: {`${dayInfo.date.day}/${dayInfo.date.month}/${dayInfo.date.year}`}</h4>

      {dayInfo.pollenTypeInfo && (
        <div className="pollen-section">
          <h5 style={{ color: '#3a8b3a' }}>🌳 Pollen Type Information (TREE)</h5>
          {dayInfo.pollenTypeInfo
            .filter(pollen => pollen.code === 'TREE')
            .map((pollen, idx) => (
              <div key={idx} className="pollen-details">
                <p>
                  <strong>{pollen.displayName}</strong>:{' '}
                  {pollen.indexInfo?.category ? (
                    <span>{pollen.indexInfo.category} (UPI: {pollen.indexInfo.value})</span>
                  ) : (
                    <span>No category information available</span>
                  )}
                </p>
                <p>{pollen.indexInfo?.indexDescription || 'No description available'}</p>
                {pollen.healthRecommendations && (
                  <p style={{ color: '#0a8a0a' }}>🌟 Recommendations: {pollen.healthRecommendations.join(', ')}</p>
                )}
              </div>
            ))}
        </div>
      )}

      {dayInfo.plantInfo && (
        <div className="plant-section">
          <h5 style={{ color: '#3a8b3a' }}>🌲 Plant Information (Cypress Pine)</h5>
          {dayInfo.plantInfo
            .filter(plant => plant.displayName === 'Cypress Pine')
            .map((plant, idx) => (
              <div key={idx} className="plant-details">
                <p><strong>{plant.displayName}</strong></p>
                {plant.indexInfo?.indexDescription && <p>{plant.indexInfo.indexDescription}</p>}
                {plant.plantDescription && (
                  <div className="plant-description">
                    {plant.plantDescription.specialColors && (
                      <p>🎨 Colors: {plant.plantDescription.specialColors}</p>
                    )}
                    {plant.plantDescription.specialShapes && (
                      <p>🔺 Shapes: {plant.plantDescription.specialShapes}</p>
                    )}
                  </div>
                )}
                {plant.plantDescription.crossReaction && (
                  <p style={{ color: '#d9534f' }}>⚠️ Cross-reaction: {plant.plantDescription.crossReaction}</p>
                )}
                {plant.plantDescription.picture && (
                  <div className="plant-images">
                    <img
                      src={plant.plantDescription.picture}
                      alt={`${plant.displayName} full view`}
                      style={{ width: '100px', margin: '5px' }}
                    />
                    <img
                      src={plant.plantDescription.pictureCloseup}
                      alt={`${plant.displayName} close-up`}
                      style={{ width: '100px', margin: '5px' }}
                    />
                  </div>
                )}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default PollenDataDisplay;
