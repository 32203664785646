import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './HeroSection.css';

function HeroSection() {
    const [postcode, setPostcode] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const apiUrl = 'http://54.87.57.66:3000/api';

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Validate postcode
        if (!/^\d{4}$/.test(postcode)) {
            setError('Postcode must be a 4-digit number');
            return;
        }

        setError(''); 

        try {
            // Fetch GeoJSON data
            const response = await fetch(`${apiUrl}/geojson1?postcode=${postcode}`);

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();

            if (data.features && data.features.length > 0) {
                // Redirect to /places page with postcode data
                navigate('/places', { state: { postcode, suburbBoundary: data } });
            } else {
                setError('No data found for the given postcode');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setError('Failed to fetch data. Please try again.');
        }
    };

    return (
        <section className="hero-section">
            <div className="container">
                <div className="hero-content">
                    <h1>Find hospital services in your area</h1>
                    
                    <form className="search-form" onSubmit={handleSubmit}>
                        <input 
                            type="text" 
                            placeholder="Enter postcode (4 digits)" 
                            value={postcode}
                            onChange={(e) => setPostcode(e.target.value)}
                            aria-label="Postcode"
                        />
                        <button type="submit">Find Hospitals</button>
                        {error && <p className="error">{error}</p>}
                    </form>
                </div>
            </div>
        </section>
    );
}

export default HeroSection;
