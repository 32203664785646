import React, { useEffect, useState, useCallback, useRef } from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api'; // Removed InfoWindow import
import { useLocation } from 'react-router-dom';
import debounce from 'lodash/debounce'; // Ensure lodash is installed

const libraries = ['places'];
const googleMapsApiKey = 'AIzaSyBQ9LV79BsIx2z8g2MpH1uG3q82cwFHZPU'; // Ensure this is valid

const calculateCentroid = (coordinates) => {
  let x = 0;
  let y = 0;
  let numPoints = coordinates.length;

  for (let i = 0; i < numPoints; i++) {
    x += coordinates[i][0];
    y += coordinates[i][1];
  }

  return { lat: y / numPoints, lng: x / numPoints };
};

const MapLoader = ({ center, onMapLoad }) => (
  <GoogleMap
    mapContainerStyle={styles.mapContainerStyle}
    center={center}
    zoom={12}
    onLoad={onMapLoad}
    options={{ zoomControl: true }}
  />
);

const HospitalList = () => {
  const [center, setCenter] = useState({ lat: -37.8136, lng: 144.9631 });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [geoJsonData, setGeoJsonData] = useState(null);
  const [hospitalMarkers, setHospitalMarkers] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const mapRef = useRef(null);
  const markerRef = useRef([]);

  const apiUrl = 'http://54.87.57.66:3000/api';
  const location = useLocation();
  const postcode = location.state?.postcode || '';

  const fetchGeoJsonData = useCallback(async () => {
    try {
      if (postcode) {
        const response = await fetch(`${apiUrl}/geojson1?postcode=${postcode}`);
        if (response.ok) {
          const geoJson = await response.json();
          setGeoJsonData(geoJson);
          if (geoJson.features.length > 0) {
            const coordinates = geoJson.features[0].geometry.coordinates[0];
            const centroid = calculateCentroid(coordinates);
            setCenter(centroid);
          }
        } else {
          setError('Failed to fetch boundary data.');
        }
      }
    } catch (error) {
      console.error('Error fetching GeoJSON data:', error);
      setError('Failed to fetch boundary data. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [postcode]);

  const fetchNearbyHospitals = useCallback(debounce(() => {
    console.log('Fetching nearby hospitals...');
    if (mapRef.current && typeof window !== 'undefined' && window.google) {
      const service = new window.google.maps.places.PlacesService(mapRef.current);
      const centerLatLng = mapRef.current.getCenter();
      if (centerLatLng) {
        const request = {
          location: centerLatLng,
          radius: '3000', // 3 km radius
          type: 'hospital',
        };

        service.nearbySearch(request, (results, status) => {
          console.log('Places API results:', results);
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            // Fetch detailed information for each place
            const detailsPromises = results.map(place => {
              return new Promise((resolve, reject) => {
                service.getDetails({ placeId: place.place_id }, (details, status) => {
                  if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    resolve({
                      position: {
                        lat: details.geometry.location.lat(),
                        lng: details.geometry.location.lng(),
                      },
                      title: details.name,
                      address: details.formatted_address,
                      rating: details.rating,
                      hours: details.opening_hours?.weekday_text || ['Not Available'],
                      phone: details.formatted_phone_number,
                    });
                  } else {
                    reject(new Error('Failed to fetch details'));
                  }
                });
              });
            });

            Promise.all(detailsPromises).then(markers => {
              setHospitalMarkers(markers);
              console.log('Markers:', markers);

              // Add markers to the map
              addMarkers(markers, 'red', markerRef);
            }).catch(error => {
              console.error('Error fetching details:', error);
              setError('Failed to fetch detailed hospital information. Please try again.');
            });
          } else {
            console.error('Places API error: ', status);
            setError('Failed to fetch hospitals. Please try again.');
          }
        });
      }
    }
  }, 1000), [mapRef]);

  const addMarkers = (markers, color, refArray) => {
    // Remove existing markers
    refArray.current.forEach(marker => marker.setMap(null));
    refArray.current = []; // Clear the ref array

    // Add new markers
    markers.forEach((marker) => {
      const mapMarker = new window.google.maps.Marker({
        position: marker.position,
        map: mapRef.current,
        title: marker.title,
        icon: {
          url: `http://maps.google.com/mapfiles/ms/icons/${color}-dot.png`,
        },
      });
      mapMarker.addListener('click', () => {
        setSelectedMarker(marker);
      });
      refArray.current.push(mapMarker);
    });
  };

  const onMapLoad = useCallback((map) => {
    console.log('Map loaded:', map);
    mapRef.current = map;

    if (geoJsonData) {
      const highlightBounds = new window.google.maps.LatLngBounds();
      geoJsonData.features.forEach((feature) => {
        feature.geometry.coordinates[0].forEach(([lng, lat]) => {
          highlightBounds.extend(new window.google.maps.LatLng(lat, lng));
        });
      });
      map.fitBounds(highlightBounds);
      map.data.addGeoJson(geoJsonData);
      map.data.setStyle({
        fillColor: '#e7c8a0',
        strokeColor: 'grey',
        strokeWeight: 2,
        fillOpacity: 0.6,
      });

      // Set a fixed zoom level after fitting to the boundary
      const zoomLevel = map.getZoom();
      map.setZoom(zoomLevel);
    }

    fetchNearbyHospitals();
  }, [geoJsonData, fetchNearbyHospitals]);

  useEffect(() => {
    setLoading(true);
    fetchGeoJsonData();
  }, [fetchGeoJsonData, postcode]);

  return (
    <div style={styles.container}>
            <h1 style={styles.title}>Hospital Finder for Postcode: {postcode}</h1>
      <div style={styles.mapAndInfo}>
        <LoadScript googleMapsApiKey={googleMapsApiKey} libraries={libraries}>
          <MapLoader center={center} onMapLoad={onMapLoad} />
        </LoadScript>
        <div style={styles.infoPanel}>
          {selectedMarker ? (
            <div>
              <h2>{selectedMarker.title}</h2>
              <p><strong>Address:</strong> {selectedMarker.address}</p>
              <p><strong>Rating:</strong> {selectedMarker.rating ? `${selectedMarker.rating} ⭐` : 'No rating available'}</p>
              <p><strong>Phone:</strong> {selectedMarker.phone || 'Not available'}</p>
              <p><strong>Operating Hours:</strong></p>
              <ul>
                {selectedMarker.hours.length > 0 ? selectedMarker.hours.map((hour, index) => (
                  <li key={index}>{hour}</li>
                )) : <li>Not available</li>}
              </ul>
            </div>
          ) : (
            <p>Select a hospital to see details</p>
          )}
        </div>
      </div>
      {error && <p className="error">{error}</p>}
    </div>
  );
};

const styles = {
  container: {
    width: '100%',
    maxWidth: '1200px',
    margin: 'auto',
    padding: '20px',
  },
  mapAndInfo: {
    display: 'flex',
  },
  mapContainerStyle: {
    width: '75%',
    height: '600px',
    border: '5px solid #ccc',
    borderRadius: '10px',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)',
  },
  infoPanel: {
    width: '25%',
    padding: '20px',
    borderLeft: '1px solid #ccc',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)',
  },
};

export default HospitalList;