import React from 'react';
import './KnowledgeBase.css';

import tram from './tram.jpg';
import events from './events.jpg';

function KnowledgeBase() {
    return (
        <div className="knowledge-base">
            <header className="knowledge-base-header">
                <h1>Welcome to Melbourne</h1>
                <p>Your go-to resource for settling into Melbourne smoothly. Find essential information and local guides below.</p>
            </header>
            <section className="knowledge-base-cards">
                <div className="knowledge-base-card">
                    <h2>Essential Information</h2>
                    <img src={tram} alt="transport" className="knowledge-base-card-image1" />
                       
                    <ul>
                         <li><a href="https://www.health.gov.au/contacts" target="_blank" rel="noopener noreferrer">Healthcare Services</a></li>
                        <li><a href="https://liveinmelbourne.vic.gov.au/live/housing-and-property/renting-a-house-or-apartment" target="_blank" rel="noopener noreferrer">Housing and Accommodation</a></li>
                        <li><a href="https://ptv.vic.gov.au/" target="_blank" rel="noopener noreferrer">Public Transport</a></li>
                    
                    </ul>
                </div>
                <div className="knowledge-base-card">
                    <h2>Guides and Resources</h2>
                    <img src={events} alt="event" className="knowledge-base-card-image" />
                     
                    <ul>
                        <li><a href="https://whatson.melbourne.vic.gov.au/" target="_blank" rel="noopener noreferrer">Local Events</a></li>
                        <li><a href="https://www.jobsearch.gov.au/" target="_blank" rel="noopener noreferrer">Job Search Resources</a></li>
                        <li><a href="https://www.melbourne.vic.gov.au/safe-city-cameras" target="_blank" rel="noopener noreferrer">Surveillance Cameras</a></li>
                        <li><a href="https://www.melbourne.vic.gov.au/internationalstudents" target="_blank" rel="noopener noreferrer">International Students</a></li>
                        <li><a href="https://www.tripzilla.com/best-things-to-do-melbourne/142744" target="_blank" rel="noopener noreferrer">Best things to do in Melbourne</a></li>
                       
                    </ul>
                </div>
            </section>
        </div>
    );
}

export default KnowledgeBase;
