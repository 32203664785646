import React, { useState, useEffect } from "react";
import sanitizeHtml from 'sanitize-html'; 
import YouTubeVideos from './YouTubeVideos';
import './FAQ.css';
const data = {
    title: "Safety FAQs",
    rows: [
       
        {
            title: "How to secure your home effectively?",
            content: [
                "Install a comprehensive security system with cameras and alarms.",
                "Use deadbolt locks on all exterior doors.",
                "Keep windows and doors locked at all times.",
                "Install outdoor lighting and consider a smart doorbell for monitoring.",
                "Develop a fire escape plan and practice it regularly.",
                '<a href="https://www.police.vic.gov.au/personal-safety" target="_blank" rel="noopener noreferrer">For more home safety tips, visit Victoria Police Home Safety</a>'
            ],
            category: "Personal Safety",
        },
        {
            title: "How to stay safe on public transport?",
            content: [
                "Keep your personal belongings close and avoid placing valuables in easy-to-reach areas.",
                "Avoid traveling alone late at night if possible; choose well-lit and busy areas.",
                "Report any suspicious behavior to transport staff or security.",
                "Know the route and stops in advance, and plan your journey ahead of time.",
                '<a href="https://www.ptv.vic.gov.au/safety" target="_blank" rel="noopener noreferrer">For more tips, check out Public Transport Victoria Safety</a>'
            ],
            category: "Personal Safety",
        },
        {
            title: "How can I stay safe in public places in Victoria?",
            content: [
                "Be aware of your surroundings and stay in well-lit areas.",
                "Report any suspicious behavior to local authorities.",
                "Use public services and community resources for safety tips and assistance.",
                '<a href="https://www.crimeprevention.vic.gov.au/case-study-feeling-safe-in-public-places" target="_blank" rel="noopener noreferrer">For safety in public places, see Crime Prevention Victoria</a>.'
            ],
            category: "Personal Safety",
        },
        {
            title: "Where can I find information on personal safety in Melbourne?",
            content: [
                '<a href="https://www.melbourne.vic.gov.au/news/how-were-helping-keep-melbourne-safe" target="_blank" rel="noopener noreferrer">Visit the Melbourne City Council website for resources on safety in Melbourne</a>.',
                '<a href="https://www.studymelbourne.vic.gov.au/living-here/health-safety-and-wellbeing/some-tips-for-staying-safe" target="_blank" rel="noopener noreferrer">Study Melbourne provides tips on health, safety, and well-being for international students and residents</a>.',
                '<a href="https://www.police.vic.gov.au/personal-safety" target="_blank" rel="noopener noreferrer">The Victoria Police website offers guidance on personal safety and crime prevention</a>.'
            ],
            category: "Personal Safety",
        },
        {
            title: "What are some tips for staying safe while Travelling in Melbourne?",
            content: [
                "Stay aware of your surroundings and avoid isolated areas, especially at night.",
                "Use reputable transportation options and verify the identity of your driver if using rideshare services.",
                "Keep your belongings secure and avoid displaying valuable items in public.",
                "Know emergency contact numbers and local emergency services.",
                "Register with Local Authorities or consulates if travelling from abroad.",
                '<a href="https://www.melbourne.vic.gov.au/news/how-were-helping-keep-melbourne-safe" target="_blank" rel="noopener noreferrer">For local emergency services, visit Melbourne Emergency Services</a>'
            ],
            category: "Travel Safety",
        },

   
        {
            title: "Where can I access health and Well-being resources in Melbourne?",
            content: [
                "Melbourne has numerous health clinics and hospitals offering a range of medical services.",
                "Mental health support services are available through local community health centers and hotlines.",
                "Visit local government health websites or community centers for information on health services.",
                "Ensure you have adequate health insurance and understand your coverage options.",
                '<a href="https://www.vu.edu.au/study-at-vu/life-at-vu/safety-in-melbourne" target="_blank" rel="noopener noreferrer">Find health resources here: Health and Wellness Melbourne</a>'
            ],
            category: "Health and Wellbeing",
        },

       
        {
            title: "What cultural customs should I be aware of in Melbourne?",
            content: [
                "Australians value punctuality and honesty; be on time for appointments and be straightforward in communication.",
                "Respect local customs such as queueing and polite interactions.",
                "Familiarise yourself with local laws, such as regulations regarding alcohol consumption and public behaviour.",
                "Understanding and adhering to these customs can help you integrate smoothly into the community.",
                '<a href="https://www.studymelbourne.vic.gov.au/living-here/health-safety-and-wellbeing/some-tips-for-staying-safe" target="_blank" rel="noopener noreferrer">Learn more about cultural practices: Study Melbourne Cultural Adaptation</a>'
            ],
            category: "Cultural Adaptation",
        },

   
        {
            title: "What resources are available for women’s safety in Australia?",
            content: [
                '<a href="https://www.pmc.gov.au/office-women/womens-safety#:~:text=1800RESPECT%20is%20a%20national%20sexual,1800RESPECT%20on%201800%20737%20732)" target="_blank" rel="noopener noreferrer">1800RESPECT provides support for sexual assault and domestic violence</a>.',
                '<a href="https://safeandequal.org.au/" target="_blank" rel="noopener noreferrer">Safe and Equal offers information and support for women experiencing violence</a>.',
                '<a href="https://www.aihw.gov.au/family-domestic-and-sexual-violence/resources/fdsv-summary" target="_blank" rel="noopener noreferrer">Visit the Australian Institute of Health and Welfare for data on domestic and sexual violence</a>.',
                '<a href="https://www.premier.vic.gov.au/changing-laws-and-culture-save-womens-lives" target="_blank" rel="noopener noreferrer">Check the Victorian Government\'s resources on changing laws and saving women’s lives</a>.'
            ],
            category: "Personal Safety",
        },
    ],
};


const categories = Array.from(new Set(data.rows.map(row => row.category).concat(["All"])));

const categoryQueries = {
     "All": "safety tips in melbourne",
    "Personal Safety": "tips for personal safety in melbourne",
    "Travel Safety": "tips for travel safety in melbourne",
    "Health and Wellbeing": "tips for health and wellbeing in melbourne",
    "Cultural Adaptation": "tips for cultural adaptation in melbourne",
   
};

export default function FAQ() {
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("All");
    const [showScrollToTop, setShowScrollToTop] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);
    const [showVideos, setShowVideos] = useState(false); // State to manage view toggle

    const sanitizedQuery = sanitizeHtml(searchQuery);

    const filteredRows = data.rows.filter(row => {
        const matchesSearch = row.title.toLowerCase().includes(sanitizedQuery.toLowerCase());
        const matchesCategory = selectedCategory === "All" || row.category === selectedCategory;
        return matchesSearch && matchesCategory;
    });

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
    };

    const handleScroll = () => {
        if (window.scrollY > 300) {
            setShowScrollToTop(true);
        } else {
            setShowScrollToTop(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 768);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className="faq-container">
            <div className="faq-header">
                <h1 className="faq-title">{data.title}</h1>
                <input
                    type="text"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className="faq-search"
                />
            </div>

            <div className="faq-categories">
                {categories.map((category) => (
                    <button
                        key={category}
                        className={`faq-category ${selectedCategory === category ? 'active' : ''}`}
                        onClick={() => handleCategoryClick(category)}
                    >
                        {category}
                    </button>
                ))}
            </div>

            <div className="faq-content">
                {isSmallScreen ? (
                    <>
                        <div className="faq-toggle-buttons">
                            <button className="faq-toggle-button" onClick={() => setShowVideos(false)}>Questions</button>
                            <button className="faq-toggle-button" onClick={() => setShowVideos(true)}>Videos</button>
                        </div>
                        {showVideos ? (
                            <div className="faq-videos">
                                <h3>More from YouTube</h3>
                                {selectedCategory in categoryQueries && (
                                    <YouTubeVideos query={categoryQueries[selectedCategory]} />
                                )}
                            </div>
                        ) : (
                            <div className="faq-questions">
                                {filteredRows.map((row, index) => (
                                    <div key={index} className="faq-item">
                                        <h3 className="faq-question">{row.title}</h3>
                                        <div className="faq-answer">
                                            {row.content.map((point, i) => (
                                                <div key={i} dangerouslySetInnerHTML={{ __html: point }} />
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <div className="faq-questions">
                            {filteredRows.map((row, index) => (
                                <div key={index} className="faq-item">
                                    <h3 className="faq-question">{row.title}</h3>
                                    <div className="faq-answer">
                                        {row.content.map((point, i) => (
                                            <div key={i} dangerouslySetInnerHTML={{ __html: point }} />
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>

                        {selectedCategory in categoryQueries && (
                            <div className="faq-videos">
                                <h3>More from YouTube</h3>
                                <YouTubeVideos query={categoryQueries[selectedCategory]} />
                            </div>
                        )}
                    </>
                )}
            </div>

            {showScrollToTop && (
                <button className="scroll-to-top" onClick={scrollToTop}>
                    ↑
                </button>
            )}
        </div>
    );
}