import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './pages/Homepage/HomePage';
import FAQ from './pages/FAQ/FaqPage';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import KnowledgeBase from './pages/KnowledgeBase/KnowledgePage';
import Chatbox from './features/Chatbot/Chatbot';
import Safety from './pages/SafetyPage/SafetyPage';
import PasswordPrompt from './components/Password/PasswordPrompt';
import MapPoints from './pages/MapPoints/MapPoints';
import PollenForecastPage from './pages/PollenData/PollenForecastPage';
import HospitalsList from './pages/MapPoints/HospitalsList.js';
import AllergyRiskAssessment from './pages/AllergyRiskAssessment/AllergyRiskAssessment.js';
function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    return (
        <div className="App">
            {isAuthenticated ? (
                <Router>
                    <Header />
                    <main>
                        <Routes>
                            <Route path="/" element={<LandingPage />} />
                            <Route path="/safety" element={<Safety />} />
                            <Route path="/faq" element={<FAQ />} />
                            <Route path="/knowledgebase" element={<KnowledgeBase />} />
                            <Route path="/map" element={<MapPoints />} />
                            <Route path="/pollen" element={<PollenForecastPage />} />
                            <Route path="/places" element={<HospitalsList />} />
                            <Route path="/allergy" element={<AllergyRiskAssessment />} />
                        </Routes>
                    </main>
                    <Chatbox />
                    <Footer />
                </Router>
            ) : (
                <PasswordPrompt onPasswordVerified={setIsAuthenticated} />
            )}
        </div>
    );
}

export default App;