import React, { useState } from 'react';
import './AllergyRiskAssessment.css';

const AllergyRiskAssessment = () => {
    const [step, setStep] = useState(1);
    const [answers, setAnswers] = useState({
        allergens: [],
        environment: [],
        sensitivity: [],
        dailyActivities: [],
        generalHealth: []
    });

    const handleNext = () => setStep(step + 1);
    const handlePrevious = () => setStep(step - 1);

    const handleCheckboxChange = (section, value) => {
        setAnswers((prevAnswers) => {
            const sectionAnswers = prevAnswers[section];
            if (sectionAnswers.includes(value)) {
                return {
                    ...prevAnswers,
                    [section]: sectionAnswers.filter((item) => item !== value),
                };
            }
            return {
                ...prevAnswers,
                [section]: [...sectionAnswers, value],
            };
        });
    };

    const generateRecommendations = () => {
        let recommendations = [];

        if (answers.allergens.includes("Pollen")) {
            recommendations.push("Consider using an air purifier in your home to reduce pollen exposure.");
        }
        if (answers.allergens.includes("Pet hair")) {
            recommendations.push("Try to keep pets out of bedrooms and regularly groom them to reduce allergens.");
        }
        if (answers.environment.includes("Increased indoor dust")) {
            recommendations.push("Use hypoallergenic bedding and clean your home regularly with a HEPA filter vacuum.");
        }
        if (answers.sensitivity.includes("Yes, I feel more prone to sneezing or itchy eyes")) {
            recommendations.push("Consider consulting with an allergist for a professional evaluation and treatment plan.");
        }
        if (answers.dailyActivities.includes("Frequent outdoor activities")) {
            recommendations.push("Check local pollen forecasts and plan outdoor activities when pollen counts are lower.");
        }
        if (answers.generalHealth.includes("Climate changes")) {
            recommendations.push("Be aware of how weather changes affect you and prepare accordingly, such as using a humidifier in dry conditions.");
        }

        if (recommendations.length === 0) {
            recommendations.push("While you don't seem to have significant allergy concerns, it's always good to maintain a clean living environment and be aware of any changes in your health.");
        }

        return recommendations;
    };

    const renderQuestionStep = () => {
        switch (step) {
            case 1:
                return (
                    <div className="question-step">
                        <h2 className="question-title">1.1. Recent Exposure to Potential Allergens</h2>
                        <img src="/images/q1.png" alt="Allergens" className="question-image" />
                        <p className="question-text">Have you recently been exposed to any of the following? (Select all that apply):</p>
                        {["Pollen", "Pet hair", "Dust or dust mites", "Mold", "Smoke", "Certain food or drink ingredients"].map((option) => (
                            <div key={option} className="option-container">
                                <input
                                    type="checkbox"
                                    id={option}
                                    value={option}
                                    onChange={() => handleCheckboxChange('allergens', option)}
                                    className="option-input"
                                />
                                <label htmlFor={option} className="option-label">{option}</label>
                            </div>
                        ))}
                        <input type="text" placeholder="Other (Please describe)" className="other-input" />
                    </div>
                );
            case 2:
                return (
                    <div className="question-step">
                        <h2 className="question-title">1.2. Changes in Living Environment</h2>
                        <img src="/images/q2.png" alt="Living Environment" className="question-image" />
                        <p className="question-text">Have you noticed any of the following changes in your living environment recently?</p>
                        {["Increased indoor dust", "Moved to area with more pollen", "New pets at home", "Increased humidity", "Decreased air quality"].map((option) => (
                            <div key={option} className="option-container">
                                <input
                                    type="checkbox"
                                    id={option}
                                    value={option}
                                    onChange={() => handleCheckboxChange('environment', option)}
                                    className="option-input"
                                />
                                <label htmlFor={option} className="option-label">{option}</label>
                            </div>
                        ))}
                        <input type="text" placeholder="Other (Please describe)" className="other-input" />
                    </div>
                );
            case 3:
                return (
                    <div className="question-step">
                        <h2 className="question-title">1.3. Reaction to Environmental Exposure</h2>
                        <img src="/images/q3.png" alt="Reaction to Environment" className="question-image" />
                        <p className="question-text">Have you noticed any changes in your health after exposure to certain environments or items?</p>
                        {[
                            "Yes, I feel more prone to sneezing or itchy eyes",
                            "No, I haven't noticed any changes",
                            "Unsure"
                        ].map((option) => (
                            <div key={option} className="option-container">
                                <input
                                    type="radio"
                                    id={option}
                                    name="reaction"
                                    value={option}
                                    onChange={() => handleCheckboxChange('sensitivity', option)}
                                    className="option-input"
                                />
                                <label htmlFor={option} className="option-label">{option}</label>
                            </div>
                        ))}
                    </div>
                );
            case 4:
                return (
                    <div className="question-step">
                        <h2 className="question-title">2.1. Living Environment Conditions</h2>
                        <img src="/images/q4.png" alt="Living Environment Conditions" className="question-image" />
                        <p className="question-text">Do any of the following conditions exist in your living environment?</p>
                        {["Prolonged exposure to air conditioning", "Spending time with pets", "Frequent outdoor activities", "Using cleaning products", "Poor indoor air circulation"].map((option) => (
                            <div key={option} className="option-container">
                                <input
                                    type="checkbox"
                                    id={option}
                                    value={option}
                                    onChange={() => handleCheckboxChange('dailyActivities', option)}
                                    className="option-input"
                                />
                                <label htmlFor={option} className="option-label">{option}</label>
                            </div>
                        ))}
                        <input type="text" placeholder="Other (Please describe)" className="other-input" />
                    </div>
                );
            case 5:
                return (
                    <div className="question-step">
                        <h2 className="question-title">3.1. Sensitivity to Environmental Changes</h2>
                        <img src="/images/q5.png" alt="Sensitivity" className="question-image" />
                        <p className="question-text">Do you have heightened sensitivity to any of the following?</p>
                        {["Climate changes", "New environments", "Indoor dust", "Certain food or drink ingredients"].map((option) => (
                            <div key={option} className="option-container">
                                <input
                                    type="checkbox"
                                    id={option}
                                    value={option}
                                    onChange={() => handleCheckboxChange('generalHealth', option)}
                                    className="option-input"
                                />
                                <label htmlFor={option} className="option-label">{option}</label>
                            </div>
                        ))}
                        <input type="text" placeholder="Other (Please describe)" className="other-input" />
                    </div>
                );
            case 6:
                const recommendations = generateRecommendations();
                return (
                    <div className="completion-step">
                        <h2 className="completion-title">Thank you for completing the questionnaire!</h2>
                        <div className="recommendations-container">
                            <p className="recommendations-intro">Based on your answers, here are some personalized recommendations:</p>
                            <ul className="recommendations-list">
                                {recommendations.map((recommendation, index) => (
                                    <li key={index} className="recommendation-item">
                                        <span className="recommendation-icon">✔</span>
                                        {recommendation}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <p className="disclaimer">
                            Please note: These recommendations are general advice. For specific medical concerns, always consult with a healthcare professional.
                        </p>
                    </div>
                );
            default:
                return <div className="completion-message">Thank you for completing the questionnaire!</div>;
        }
    };

    return (
        <div className="questionnaire-container">
            <div className="question-content">
                {renderQuestionStep()}
            </div>
            <div className="button-container">
                {step > 1 && step <= 6 && (
                    <button
                        onClick={handlePrevious}
                        className="button button-previous"
                    >
                        Previous
                    </button>
                )}
                {step < 6 ? (
                    <button
                        onClick={handleNext}
                        className="button button-next"
                    >
                        Next
                    </button>
                ) : (
                    <button
                        onClick={() => alert('Questionnaire Complete!')}
                        className="button button-submit"
                    >
                        Finish
                    </button>
                )}
            </div>
        </div>
    );
};

export default AllergyRiskAssessment;