import React, { useEffect, useRef } from "react";

const Legend = ({ map }) => {
  const legendRef = useRef(null);

  useEffect(() => {
    if (map && !legendRef.current) {
      const legendDiv = document.createElement("div");
      legendDiv.style.backgroundColor = "white";
      legendDiv.style.border = "1px solid #ccc";
      legendDiv.style.borderRadius = "5px";
      legendDiv.style.padding = "0.5rem"; // Use relative padding
      legendDiv.style.fontSize = "0.5rem"; // Use fluid font size
      legendDiv.style.margin = "1rem";
      legendDiv.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)";
      legendDiv.style.maxWidth = "15%"; // Relative to container size
      legendDiv.style.width = "fit-content"; // Allow it to grow naturally
      legendDiv.style.zIndex = "999";

      const title = document.createElement("div");
      title.style.fontWeight = "bold";
      title.innerHTML = "Crime Offense Levels";
      legendDiv.appendChild(title);

      const colors = [
        { color: "#FF7F50", label: "Very High (>10,000)" },
        { color: "#FFA07A", label: "High (>5,000)" },
        { color: "#87CEEB", label: "Moderate (>500)" },
        { color: "#4682B4", label: "Low (>100)" },
        { color: "#B0C4DE", label: "Very Low (<=100)" },
      ];

      colors.forEach(({ color, label }) => {
        const row = document.createElement("div");
        row.style.display = "flex";
        row.style.alignItems = "center";
        row.style.marginBottom = "0.5rem"; // Relative margin

        const colorBox = document.createElement("div");
        colorBox.style.backgroundColor = color;
        colorBox.style.width = "1.5rem"; // Relative width and height
        colorBox.style.height = "1.5rem";
        colorBox.style.marginRight = "0.75rem"; // Relative margin
        row.appendChild(colorBox);

        const labelText = document.createElement("span");
        labelText.innerHTML = label;
        row.appendChild(labelText);

        legendDiv.appendChild(row);
      });

      map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(legendDiv);
      legendRef.current = legendDiv;
    }

    return () => {
      if (map && legendRef.current) {
        map.controls[window.google.maps.ControlPosition.TOP_RIGHT].clear();
        legendRef.current = null;
      }
    };
  }, [map]);

  return null;
};

export default Legend;
