import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';

const Header = () => {
    const location = useLocation();
    const currentPath = location.pathname;
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header className="navbar">
            <nav className="navbar-container">
                <div className="navbar-logo">
                    <Link to="/">
                    </Link>
                    <Link to="/" className="site-title">Live Safe</Link>
                </div>

                <div className="menu-icon" onClick={toggleMenu}>
                    <i className={isMenuOpen ? "fas fa-times" : "fas fa-bars"}></i>
                </div>

                <ul className={`navbar-menu ${isMenuOpen ? "open" : ""}`}>
                    <li className={currentPath === '/' ? 'active' : ''}>
                        <Link to="/" onClick={toggleMenu}>Home</Link>
                    </li>
                    <li className={currentPath === '/safety' ? 'active' : ''}>
                        <Link to="/pollen" onClick={toggleMenu}>Pollen Tracker</Link>
                    </li>
                    <li className={currentPath === '/faq' ? 'active' : ''}>
                        <Link to="/allergy" onClick={toggleMenu}>Allergy Suggestions</Link>
                    </li>
                    <li className={currentPath === '/faq' ? 'active' : ''}>
                        <Link to="/faq" onClick={toggleMenu}>FAQ</Link>
                    </li>
                </ul>
            </nav>
        </header>
    );
};

export default Header;
